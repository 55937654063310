<template>
  <div class="container-fluid">
    <div class="row mt-5" style="margin-top: 200px">
      <div class="col-12 text-center mt-5">
        <div class="container">
          <h3 class="text-secondary font">
            Sabemos que trabajas y estudias: tu tiempo es limitado
          </h3>
        </div>
      </div>
      <div class="col-12 text-center">
        <div class="container">
          <img
            load="lazy"
            class="card-img"
            src="../../../assets/image-56.webp"
            alt="Biblioteca"
          />
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="container">
          <p class="text-secondary font text-justify">
            Nuestra misión es ofrecerte todas las herramientas necesarias para
            ayudarte a conseguir el empleo de tus sueños y logres un ascenso
            laboral. Es por eso que optamos por una metodología de e-learning en
            todos nuestros diplomados, porque es flexible, rápida y de calidad
          </p>
        </div>
        <div class="container">
          <p class="text-secondary font text-justify">
            Si deseas capacitarte como prevencionista, implementador o
            convertirte en supervisor de Seguridad y Salud Ocupacional, te
            recomiendo que conozcas el caso de José (con el que quizás te
            identifiques):
          </p>
        </div>
        <div class="col-12 px-5">
          <div class="container px-5">
            <p class="text-secondary font text-justify">
              José, recién graduado de la secundaria y sin experiencia laboral,
              quería comenzar a trabajar pero no lograba conseguir un empleo.
            </p>
            <p class="text-secondary font text-justify">
              Todos los días se metía en CompuTrabajo y se postulaba a cualquier
              vacante que encontraba, pero siempre pasaba lo mismo: por cada
              vacante ya había mil personas postulándose y nunca lo llamaban.
            </p>
            <p class="text-secondary font text-justify">
              Quizás se debía a que no contaba con experiencia laboral ni una
              carrera universitaria, pero tampoco estaba muy interesado en
              realizarla: José prefería una educación práctica y rápida para
              comenzar a trabajar.
            </p>
            <p class="text-secondary font text-justify">
              Un día, su amigo Andrés le comenta sobre la oportunidad de hacer
              carrera como profesional en Seguridad y Salud Ocupacional, una de
              las carreras con mayor demanda y crecimiento del país.
            </p>
            <p class="text-secondary font text-justify">
              Así, buscando en Google «Prevencionista de Riesgo en Seguridad y
              Salud Ocupacional» José se topó con IPSSOMA, el instituto de Perú
              que ofrecía diplomados en línea enfocados en ayudarle a conseguir
              trabajo como prevencionista.
            </p>
            <p class="text-secondary font text-justify">
              José toma la decisión de mejorar su vida y en menos de dos meses
              se gradúa y consigue un buen puesto en las minas de oro de Áncash.
              Desde ese día José ha podido disfrutar de la estabilidad que le
              ofrece tener un empleo, con la oportunidad de seguir mejorando su
              perfil laboral.
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="container">
            <p class="text-secondary font text-justify">
              ¿Eres como José? ¿O ya estudias, o incluso trabajas? Si
              respondiste sí a alguna de estas preguntas, estás en el lugar
              correcto.
            </p>
          </div>
          <div class="container">
            <p class="text-secondary font text-justify">
              Mi nombre es Giovany Pernía y me formé como ingeniero en la
              Universidad del Zulia. Actualmente soy el CEO del consorcio
              educativo Promolider ORG, del cual forma parte el Instituto
              Peruano SSOMA.
            </p>
          </div>
          <div class="container">
            <p class="text-secondary font text-justify">
              Al graduarme pasé mucho tiempo buscando empleo, pero ninguna
              empresa quería contratar a un ingeniero con poca experiencia
              laboral.
            </p>
          </div>
          <div class="container">
            <p class="text-secondary font text-justify">
              En la universidad nunca me prepararon para esa situación. Había
              invertido 5 años de mi vida aprendiendo conocimientos teóricos,
              del cual sabía que mucho no sería útil para la vida real. ¿Y qué
              hay sobre aprender a hacer un currículum? ¿O cómo realizar una
              entrevista de trabajo exitosa?
            </p>
          </div>
          <div class="container">
            <p class="text-secondary font text-justify">
              Un día me encontré en una cafetería a Gladys, una vieja amiga de
              la universidad, que solía ser una de las mejores en sus clases.
              Gladys me comentó que estaba trabajando como recepcionista, pero
              ¿Cómo podía ser posible que la mejor de la clase de contaduría
              estuviera trabajando como recepcionista? Lo cierto es que Gladys
              también tenía el mismo problema que yo.
            </p>
          </div>
          <div class="container">
            <p class="text-secondary font text-justify">
              Fue allí cuando surgió la idea de crear un instituto educativo
              enfocado en enseñar tanto el conocimiento teórico como práctico
              para poder ayudarte a conseguir empleo y a escalar laboralmente.
            </p>
          </div>
          <div class="container">
            <p class="text-secondary font text-justify">
              En principio, se pensó como una academia para formarte en
              habilidades blandas, que vendría a ser PROMOLIDER ORG. Pero en
              2018, debido a la baja calidad educativa y al crecimiento en el
              sector de la Seguridad y Salud Ocupacional, nace IPSSOMA con el
              objetivo de ofrecer una educación en línea flexible, rápida y de
              calidad.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <div class="container mb-5 mt-5">
          <a
            class="container-md text-dark text-decoration-none font text-justify"
            href="productos"
          >
            Descubre el diplomado que impulsará a tu perfil profesional
            <img
              load="lazy"
              class="img-fluid"
              src="@/assets/Link.webp"
              alt="Descubre el diplomado que impulsará a tu perfil profesional"
              aria-label="Descubre el diplomado que impulsará a tu perfil profesional"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",

  props: [""],
  data() {},
  components: {},
  computed: {},

  methods: {},

  watch: {},
  created() {},
};
</script>

<style>
.font {
  font-family: Roboto !important;
}

.text-justify {
  text-align: justify !important;
}
</style>
